import axios from 'axios';
import { filesApiClient } from 'src/lib/auth/filesApiClient';
import { getApiToken } from '../auth/okta';
import { config } from '../constants';
import { Address, FileServiceResult } from '../types';

export enum RepresentationType {
  BUYER = 'Buyer',
  SELLER = 'Seller',
  TENANT = 'Tenant',
  LANDLORD = 'Landlord',
}

export type FileResult = {
  id: number;
  representationType: RepresentationType;
  fileData: {
    premisesAddress: Address;
    sellers: Contact[];
    buyers: Contact[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documentData: any;
  name: string;
  contacts: Contact[];
  ownedBy: string;
};

export const getFile = async (fileId: number): Promise<FileResult> => {
  const token = await getApiToken();
  const res = await axios({
    method: 'GET',
    url: `${config.formsApiUrl}/api/files/${fileId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const result: FileResult = res.data.result;
  result.fileData.premisesAddress.streetAddress =
    `${result.fileData.premisesAddress.streetNumber} ${result.fileData.premisesAddress.streetName}`.trim();
  return result;
};

export type FormFields = {
  formURL: string;
  fields: [{ dataRef: string }];
};

export const downloadDocumentById = async (documentId: number, fileId: number): Promise<File> => {
  const token = await getApiToken();
  const url = `${config.formsApiUrl}/api/files/${fileId}/documents/download?documentIds=${documentId}`;
  const result = await axios.get<File>(url, {
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return new File([result.data], 'preview.pdf', {
    type: result.headers['content-type'],
  });
};

export type FormsFile = {
  id: number;
  createdDate: string;
  name: string;
  documentCount: number;
  representationType: string;
  ownedBy: string;
};

export interface GetFilesOptions {
  type: string;
  includeSharedFiles: boolean;
  filters: string;
}

export const getFiles = async ({
  type = 'File',
  includeSharedFiles = false,
  filters,
}: GetFilesOptions): Promise<FormsFile[]> => {
  const params = new URLSearchParams({ type, pageSize: '999', page: '1', filters });

  if (includeSharedFiles) {
    params.set('includeSharedFiles', 'true');
  }
  const res = await filesApiClient.get<FileServiceResult<{ files: FormsFile[] }>>('files', { params });

  return res.data.result.files;
};

export enum FileType {
  File = 'File',
  Template = 'Template',
  BrokerTemplate = 'BrokerTemplate',
}

export interface AddressPremises {
  unit: string;
  street: string;
}

export interface Name {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
}

export type FormFieldValue = string | AddressPremises | Address | Name[] | Contact[] | number | boolean | null;

export interface PatchFileV2Payload {
  name?: string;
  isArchived?: boolean;
  fileData?: {
    [dataRef: string]: FormFieldValue;
  };
  templateCategory?: string;
  type?: FileType;
}

export interface PatchFileV2Request {
  fileId: number;
  payload: PatchFileV2Payload;
}

export const patchFile = async (request: PatchFileV2Request): Promise<FileServiceResult<number>> => {
  const token = await getApiToken();
  return (
    await axios.patch<FileServiceResult<number>>(
      `${config.formsApiUrl}/api/files/${request.fileId}?api-version=2.0`,
      request.payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).data;
};

export interface ContactAddress {
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  unitNumber: string;
  county: string;
  id?: number;
}

export interface Contact {
  id?: number;
  type?: string;
  lastName?: string;
  middleName?: string;
  firstName?: string;
  email?: string;
  suffix?: string;
  primaryPhoneNumber?: string;
  faxPhoneNumber?: string;
  isEntity?: boolean;
  actionAttribute?: string;
  companyName?: string;
  lenderLicenseNumber?: string;
  agentLicenseNumber?: string;
  agentMLSCode?: string;
  brokerageLicenseNumber?: string;
  brokerageMLSCode?: string;
  primaryAddress?: ContactAddress;
  newContactId?: number;
  isUser?: boolean;
}

export const getContacts = async (fileId: number): Promise<Contact[]> => {
  const token = await getApiToken();
  const res = await axios({
    method: 'GET',
    url: `${config.formsApiUrl}/api/files/${fileId}/contacts`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return res.data.result;
};

export interface UploadedDocument {
  requestId: number;
  id?: number;
  thumbnailDataUrl?: string;
  progress?: number;
  name: string;
  didUploadFail?: boolean;
  document: File;
}

export interface MlsImportToFileRequest {
  fileId: number;
  shouldOverride: boolean;
  uid: string;
}

/**
 * used to import mls data to the file after creation.
 * helps with adding any missing info from MLS that normally gets imported when creating a file in Forms.
 *
 */
export const mlsImportToFile = async (payload: MlsImportToFileRequest): Promise<FileServiceResult<number>> => {
  const token = await getApiToken();
  return (
    await axios.put<FileServiceResult<number>>(`${config.formsApiUrl}/api/rpc/mlsImport`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
};
